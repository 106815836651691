import { FormEvent, useEffect, useState } from "react";
import { Trash } from "react-feather";

import { toast } from "react-toastify";
import api from "../../services/api";
import Loader from "../../components/Loader";
import { Container } from "./styles";
import { toBase64 } from "../../utils/toBase64";
import Upload from "../../components/Upload";
import { useHistory } from "react-router";
import Select from "../../components/Select";
import { statesOfBrazil } from "../../data/statesOfBrazil";
import { statesOfUruguay } from "../../data/statesOfUruguay";
import { countries } from "../../data/countries";
import { statesOfPortugal } from "../../data/statesOfPortugal";
import { ECountry, useCountry } from "../../contexts/CountryContext";
import DraggableResizableLogos, { LogosProps } from "../../components/CamLayoutEditor";
import UseLogosProps, { Image as PropsImage } from "../../components/CamLayoutEditor/UseLogosProps";
import PermittedVisitorsDialog from "../../components/PermittedVisitorsDialog";
import { Button } from "@mui/material";

export interface IOption {
  label: string;
  value: string;
}

export function AddBlock() {
  const { country } = useCountry();
  const [isAdmin, setIsAdmin] = useState(true);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [baseImage, setBaseImage] = useState<PropsImage>();
  const [advertisersLogos, setAdvertisersLogos] = useState<Array<PropsImage>>([]);

  const [establishment, setEstablishment] = useState<string>("");
  const [channels, setChannels] = useState<string>("");
  const [selectedState, setSelectedState] = useState<IOption>();
  const [selectedCountry, setSelectedCountry] = useState<IOption>();

  const [isPrivate, setIsPrivate] = useState(false);
  const [permittedVisitors, setPermittedVisitors] = useState([]);

  const [permittedVisitorsModal, setPermittedVisitorsModal] = useState(false);

  const [loader, setLoader] = useState("disabled");

  const history = useHistory();

  useEffect(() => {
    setSelectedState(undefined);
  }, [selectedCountry]);

  useEffect(() => {
    if (country !== selectedCountry?.value) {
      setSelectedCountry({
        label: country,
        value: country,
      });
    }
  }, [country, selectedCountry]);

  useEffect(() => {
    if (!establishment || !channels) return;
    try {
      setLoader("enabled");
      api
        .get('app/videos/cam-print', {
          headers: {
            establishmentFolderName: establishment,
            channelFolderName: channels
          }
        })
        .then(async (response) => {

          const upImage: PropsImage = { data: 'data:image/jpeg;base64,' + response.data.thumbnail, arrayBuffer: response.data.thumbnail } as PropsImage;
          // const { width, height } = await getImageDimensions(upImage.data)
          const upBaseImage = { ...upImage, width: 1920, height: 1080 }
          upBaseImage && setBaseImage(upBaseImage);

        }).catch(err => {
          setLoader("disabled");
          console.log(err)
        }).finally(() => {
          setLoader("disabled");
        });
    } catch (error) {
      console.log(error)
    }
  }, [establishment, channels]);

  function getImageDimensions(base64: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = base64;
    });
  }

  async function handleUploadBaseImage(uploadedImages: Array<PropsImage>) {
    let upImage = uploadedImages[0];
    upImage.data = await toBase64(uploadedImages[0]);
    const { width, height } = await getImageDimensions(upImage.data)
    upImage = { ...upImage, width, height }

    upImage['baseImage'] = true
    if (!upImage || !upImage.width || !upImage.height) {
      console.error('Imagem base inválida.');
      return;
    }

    setBaseImage(upImage);
  }

  function handleDeleteBaseImage() {
    setBaseImage(undefined);
  }

  async function handleUploadAdvertisersLogos(uploadedImages: Array<PropsImage>) {
    if (advertisersLogos.length === 10) return;

    const upImages: Array<PropsImage> = [];

    for (let image of uploadedImages) {
      // image.data = await toBase64(image);
      const { width, height } = await getImageDimensions(await toBase64(image))
      image = { ...image, data: await toBase64(image), width: image?.width || width, height: image?.height || height }
    
      if (!image.originalName) {
        image.originalName = image.path
      }
      
      upImages.push(image);
    }

    setAdvertisersLogos([...advertisersLogos, ...upImages]);
  }

  function handleDeleteAdvertisersLogos(index: number) {
    const filteredFiles = advertisersLogos.filter(
      (image: File, i: number) => i !== index
    );

    setAdvertisersLogos(filteredFiles);
  }


  const handleLogoPositions = (
    channelId: string,
    imagePath: string,
    position: { y: number; x: number; width: number; height: number }
  ) => {
    setAdvertisersLogos((prevState) => {
      const currentLogos = prevState;

      if (!currentLogos) {
        console.error(`Channel with ID ${channelId} does not exist.`);
        return prevState;
      }

      const logoIndex = currentLogos.findIndex((logo: PropsImage) => logo.path === imagePath);

      if (logoIndex === -1) {
        console.error(`Logo with path ${imagePath} does not exist for channel ${channelId}.`);
        return prevState;
      }

      const updatedLogos = currentLogos.map((logo: PropsImage, i: any) => {
        if (logo.path === imagePath) {
          return {
            ...logo,
            x: position.x,
            y: position.y,
            width: position.width,
            height: position.height,
          };
        }
        return logo;
      });

      return updatedLogos
    });
  };

  async function updateLogos(channelsIds: string[], advertisersLogos: any, logosToUpdate: any) {
    try {
      const mappedResponses = await Promise.all(
        channelsIds.map(async (channelId) => {

          if (!logosToUpdate) {
            console.warn('No logos found for channelId:', channelId);
            return [];
          }

          return await Promise.all(
            logosToUpdate.logos.map(async (logo: any) => {
              try {
                const response = await api.post('app/channel-logos', {
                  ...logosToUpdate, logos: {
                    [`${channelId}`]: [logo]
                  }
                });
                return response.data;
              } catch (err) {
                toast.error('Erro ao fazer upload de image.');
                console.error('Error updating Channel-logos for logo:', logo, err);
                return null;
              } finally {
                // toast.success('Sucesso ao fazer upload das imagens.');
              }
            })
          );
        })
      );

      toast.success('Sucesso ao fazer upload das imagens.');
      return mappedResponses;
    } catch (error) {
      console.error('Error in updating logos:', error);
    }
  }


  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (
      name.trim() === "" ||
      !selectedCountry ||
      !selectedState ||
      city.trim() === ""
    ) {
      toast.warn("Preencha todos os campos");
      return;
    }

    setLoader("activated");

    const data = new FormData();

    data.append("establishment", establishment);
    data.append("channels", channels);
    data.append("name", name);
    data.append("country", String(selectedCountry?.value) as ECountry);
    data.append("state", String(selectedState?.value));
    data.append("city", city);

    data.append("isPrivate", String(isPrivate));
    data.append(
      "permittedVisitors",
      permittedVisitors ? permittedVisitors.map((visitor: any) => visitor.value).join(",") : ''
    );

    try {
      const createdIds: { data: {establishmentId: string, channelIds: string[][]} } = await api.post("app/establishments", data)
      toast.success("Quadra cadastrada com sucesso");

      if (createdIds.data.channelIds) {
        try {
          if (advertisersLogos) {
            const logosToUpdate = {
              establishmentId: createdIds.data.establishmentId,
              channelId: createdIds.data.channelIds[0][0],
              logos: advertisersLogos
            };
            const response = await updateLogos(createdIds.data.channelIds[0], logosToUpdate.logos, logosToUpdate);
            // toast.success("Logos cadastradas com sucesso" + response);
          }
        } catch (err) {
          console.log(err);
        }
      }
      history.push("/estabelecimentos");
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error("Houve um problema de conexão, tente novamente.");
      }
    } finally {
      setLoader("disabled");
    }


  }

  return (
    <>
      <Loader className={loader} />

      <Container>
        <div className="form-container">
          <div className="box">
            <form onSubmit={handleSubmit}>
              <div className="input-block">
                <label htmlFor="establishment">Estabelecimento</label>
                <input
                  type="text"
                  id="establishment"
                  value={establishment}
                  onChange={(event) => setEstablishment(event.target.value)}
                />
              </div>

              <div className="input-block">
                <label htmlFor="channels">Canais</label>
                <input
                  type="text"
                  id="channels"
                  value={channels}
                  placeholder="Ex: canal 1,canal 2,canal 3"
                  onChange={(event) => setChannels(event.target.value)}
                />
              </div>

              <div className="input-block">
                <label htmlFor="name">Nome</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>

              <div className="input-block">
                <label htmlFor="name">Pais</label>
                <Select
                  placeholder="Selecione"
                  isMulti={false}
                  options={countries}
                  value={selectedCountry}
                  setValue={setSelectedCountry}
                />
              </div>

              {selectedCountry?.value === ECountry.Brasil && (
                <div className="input-block">
                  <label htmlFor="name">UF</label>
                  <Select
                    placeholder="Selecione"
                    isMulti={false}
                    options={statesOfBrazil}
                    value={selectedState}
                    setValue={setSelectedState}
                  />
                </div>
              )}

              {selectedCountry?.value === ECountry.Uruguai && (
                <div className="input-block">
                  <label htmlFor="name">UF</label>
                  <Select
                    placeholder="Selecione"
                    isMulti={false}
                    options={statesOfUruguay}
                    value={selectedState}
                    setValue={setSelectedState}
                  />
                </div>
              )}

              {selectedCountry?.value === ECountry.Portugal && (
                <div className="input-block">
                  <label htmlFor="name">UF</label>
                  <Select
                    placeholder="Selecione"
                    isMulti={false}
                    options={statesOfPortugal}
                    value={selectedState}
                    setValue={setSelectedState}
                  />
                </div>
              )}

              <div className="input-block">
                <label htmlFor="city">Cidade</label>
                <input
                  type="text"
                  id="city"
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                />
              </div>

              <div className="input-block">
                <div className="upload-container">
                  <span>Logos dos Patrocinadores</span>
                  <nav>
                    <ul>
                      {advertisersLogos.map((image: PropsImage, index: number) => (
                        <li key={index}>
                          <div className="div-file">
                            <img
                              className="image"
                              src={image.data}
                              alt={image.path}
                            />
                            <span className="fileName">{image.path}</span>
                          </div>
                          <button
                            className="remove-image"
                            onClick={() => handleDeleteAdvertisersLogos(index)}
                            type="button"
                          >
                            <Trash color="#f20a4d" />
                          </button>
                        </li>
                      ))}
                    </ul>
                    {advertisersLogos.length < 10 && (
                      <div className="file-add">
                        <div>
                          <Upload
                            title="Adicionar"
                            onUpload={handleUploadAdvertisersLogos}
                            maxFiles={10}
                          />
                        </div>
                      </div>
                    )}
                  </nav>
                </div>
              </div>

              <div className="input-block">
                <div className="upload-container">
                  <nav>
                    <ul>
                      {baseImage?.data && (
                        <li>
                          <div className="div-file">
                            <img className="image" src={baseImage.data} alt="Remover" />
                            <span className="fileName">{baseImage.path}</span>
                          </div>
                          <button
                            className="remove-image"
                            onClick={handleDeleteBaseImage}
                            type="button"
                          >
                            <Trash color="#f20a4d" />
                          </button>
                        </li>
                      )}
                    </ul>
                    {!baseImage && (
                      <div className="file-add">
                        <div>
                          <Upload title="Adicionar" onUpload={handleUploadBaseImage} maxFiles={1} />
                        </div>
                      </div>
                    )}
                  </nav>
                </div>
              </div>
              {baseImage && (
                <DraggableResizableLogos
                  channelId={channels}
                  isAdmin={isAdmin}
                  baseImage={baseImage}
                  onLogoPositionChange={handleLogoPositions}
                  logos={UseLogosProps({
                    advertisersLogos: advertisersLogos,
                    isAdmin
                  }) as unknown as LogosProps}
                />
              )}

              {/* <div className="input-checkbox">
                <input type="checkbox" id="watermarkInTheCenter" checked={watermarkInTheCenter} onChange={() => setWatermarkInTheCenter(!watermarkInTheCenter)} />
                <label htmlFor="watermarkInTheCenter">Adicionar Marca d'água no centro</label>
              </div> */}

              <div className="input-block-row">
                <div className="input-checkbox">
                  <input
                    type="checkbox"
                    id="private"
                    checked={isPrivate}
                    onChange={() => setIsPrivate(!isPrivate)}
                  />
                  <label htmlFor="isPrivate">Estabelecimento privado</label>
                </div>

                {isPrivate && (
                  <div className="input-button">
                    <Button
                      onClick={() => setPermittedVisitorsModal(true)}
                      size="small"
                      color="primary"
                      variant="contained"
                    >
                      Visitantes
                    </Button>
                  </div>
                )}
              </div>

              <div className="input-block">
                <button type="submit">Cadastrar</button>
              </div>
            </form>
          </div>
        </div>
      </Container>

      <PermittedVisitorsDialog
        open={permittedVisitorsModal}
        setOpen={setPermittedVisitorsModal}
        permittedVisitors={permittedVisitors}
        setPermittedVisitors={setPermittedVisitors}
      />
    </>
  );
}