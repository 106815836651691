import React from 'react';
import ReactSelect from 'react-select';

type Props = {
  placeholder?: string;
  isMulti: boolean;
  options: Array<{
    label: string;
    value: string;
  }>;
  defaultValue?: {
    label: string;
    value: string;
  };
  value?: {
    label: string;
    value: string;
  };
  setValue: (value: any) => void;
};

const Select: React.FC<Props> = ({
  placeholder,
  options,
  defaultValue,
  setValue,
  value,
  isMulti = false,
}) => {
  const colourStyles = {
    control: () => ({
      backgroundColor: '#f4f4f4',
      borderRadius: 8,
      border: 0,
      padding: 0,
      cursor: 'pointer',
      display: 'flex',
    }),
    option: (styles: any, { isDisabled }: any) => {
      return {
        ...styles,
        cursor: isDisabled ? 'default' : 'pointer',
        backgroundColor: '#f4f4f4',
        color: '#979da5',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: '#0cd864',
          color: '#000',
        },
      };
    },
  };

  return (
    <ReactSelect
      defaultValue={defaultValue}
      hideSelectedOptions={true}
      noOptionsMessage={() => 'Não Encontrado'}
      placeholder={placeholder}
      isSearchable={false}
      options={options}
      styles={colourStyles}
      onChange={(value) => setValue(value)}
      value={value}
      isMulti={isMulti}
    />
  );
};

export default Select;
