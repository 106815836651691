import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Login } from '../views/Login';

export function AuthRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route
          path="*"
          exact
          component={() => <h1>Página não encontrada</h1>}
        />
      </Switch>
    </BrowserRouter>
  );
}
