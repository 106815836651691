/* eslint-disable no-unused-vars */
import { FormEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import Toast from '../../components/Toast';
import { AuthContext } from '../../contexts/AuthContext';
import api from '../../services/api';
import { setToken } from '../../services/auth';
import { Container } from './styles';

export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loader, setLoader] = useState('disabled');

  const { setIsAuthenticated } = useContext(AuthContext);

  const history = useHistory();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (email.trim() === '' || password.trim() === '') {
      toast.warn('Preencha todos os campos');
      return;
    }

    setLoader('activated');

    try {
      const response = await api.post('app/sessions', {
        email,
        password,
      });

      setToken(response.data.token);
      setIsAuthenticated(true);

      history.push('/estabelecimentos');
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error('Houve um problema de conexão, tente novamente.');
      }
    }
    setLoader('disabled');
  };

  return (
    <>
      <Loader className={loader} />
      <Toast />
      <Container>
        <div className="form-container">
          <div className="box">
            <span>Login</span>
            <form onSubmit={handleSubmit}>
              <div className="input-block">
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <div className="input-block">
                <label htmlFor="password">Senha</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>

              <div className="input-block">
                <button type="submit">Entrar</button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
}
