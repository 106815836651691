import React, { useContext } from "react";
import { AuthRoutes } from "./AuthRoutes";
import { AppRoutes } from "./AppRoutes";
import { AuthContext } from "../contexts/AuthContext";
import { CountryProvider } from "../contexts/CountryContext";

export function Routes() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      {isAuthenticated ? (
        <CountryProvider>
          <AppRoutes />
        </CountryProvider>
      ) : (
        <AuthRoutes />
      )}
    </>
  );
}
