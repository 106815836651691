import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AddBlock } from "../views/AddBlock";
import { EditBlock } from "../views/EditBlock";
import { VisitorsList } from "../views/VisitorsList";
import EstablishmentList from "../views/EstablishmentList";
import { Layout } from "../components/Layout";
import EstablishmentHashList from "../views/EstablishmentHashList";

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Layout title="Geral">
        <Switch>
          <Route path="/" exact component={EstablishmentList} />
          <Route path="/estabelecimentos" component={EstablishmentList} />
          <Route path="/cadastrar-estabelecimento" component={AddBlock} />
          <Route path="/editar-estabelecimento/:id" component={EditBlock} />
          <Route path="/usuarios" component={VisitorsList} />
          <Route path="/settings/links" component={EstablishmentHashList} />
          <Route
            path="*"
            exact
            component={() => <h1>Página não encontrada</h1>}
          />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}
