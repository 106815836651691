import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #__root {
    width: 100%;
    min-height: 100%;
  }

  body {
    background-color: #f4f4f4;
    -webkit-font-smoothing: antialiased !important;
  }

  body,
  button,
  input,
  textarea,
  select,
  label,
  option,
  ::placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: -1px;
    color: #979da5;
    font-size: 18px;
  }

  ::selection {
    background: #0cd864;
    color: #fff;
  }

  img {
    pointer-events: none !important;
    user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
  }

  a,
  button {
    -webkit-tap-highlight-color: transparent !important;
  }
`;
