import { IOption } from "../views/AddBlock";

export const statesOfUruguay: IOption[] = [
  { label: "Artigas", value: "Artigas" },
  { label: "Canelones", value: "Canelones" },
  { label: "Cerro Largo", value: "Cerro Largo" },
  { label: "Colonia", value: "Colonia" },
  { label: "Durazno", value: "Durazno" },
  { label: "Flores", value: "Flores" },
  { label: "Florida", value: "Florida" },
  { label: "Lavalleja", value: "Lavalleja" },
  { label: "Maldonado", value: "Maldonado" },
  { label: "Montevideo", value: "Montevideo" },
  { label: "Paysandú", value: "Paysandú" },
  { label: "Río Negro", value: "Río Negro" },
  { label: "Rivera", value: "Rivera" },
  { label: "Rocha", value: "Rocha" },
  { label: "Salto", value: "Salto" },
  { label: "San José", value: "San José" },
  { label: "Soriano", value: "Soriano" },
  { label: "Tacuarembó", value: "Tacuarembó" },
  { label: "Treinta y Tres", value: "Treinta y Tres" },
];
