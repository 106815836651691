import styled from 'styled-components';

const Container = styled.div`
  .disabled {
    display: none;
  }
  .activated {
    display: block;
    background: rgba(18, 45, 84, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loader {
    border: 0.5rem solid #f3f3f3;
    border-radius: 50%;
    border-top: 0.5rem solid rgb(18, 45, 84);
    width: 4rem;
    height: 4rem;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Container;
