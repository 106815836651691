import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Container } from "./styles";
import debounce from "lodash/debounce";
import api from "../../services/api";

export default function PermittedVisitorsDialog({
  open,
  setOpen,
  permittedVisitors,
  setPermittedVisitors,
}) {
  const [inputValue, setInputValue] = useState("");
  const [emailOptions, setEmailOptions] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddEmail = (event, newValue) => {
    const inclued = permittedVisitors.find(
      (visitor) => visitor.value === newValue?.value
    );

    if (newValue && !inclued) {
      setPermittedVisitors([...permittedVisitors, newValue]);
    }

    setInputValue("");
  };

  const handleDeleteEmail = (emailToDelete) => {
    setPermittedVisitors(
      permittedVisitors.filter((email) => email !== emailToDelete)
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((newInputValue) => {
      const params = {
        limit: 10,
        offset: 0,
        search: newInputValue,
        searchfield: "email",
      };

      api.get(`app/visitors`, { params }).then((response) => {
        const visitors = response.data.data.map((visitor) => {
          return { label: visitor.email, value: visitor.visitorId };
        });
        setEmailOptions(visitors);
      });
    }, 600),
    []
  );

  const handleSearchEmails = (newInputValue) => {
    if (newInputValue) {
      setInputValue(newInputValue);
      debouncedSearch(newInputValue);
    }
  };

  return (
    <Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="sm"
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-title">Adicionar visitantes</DialogTitle>
        <DialogContent sx={{ margin: 5 }}>
          <Autocomplete
            freeSolo
            value={inputValue}
            options={emailOptions}
            renderInput={(params) => (
              <TextField {...params} label="Buscar Email" />
            )}
            onChange={(event, newValue) => {
              handleAddEmail(event, newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              handleSearchEmails(newInputValue);
            }}
          />
          <List>
            {permittedVisitors.map((email, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteEmail(email)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={email.label} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ margin: 2 }}>
          <Button
            onClick={handleClose}
            size="small"
            color="primary"
            variant="contained"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
