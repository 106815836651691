import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export enum ECountry {
  Brasil = "BR",
  Portugal = "PT",
  Uruguai = "UY",
}

type TCountryContext = {
  country: ECountry;
  setCountry: (country: ECountry) => void;
};

type TCountryProviderProps = {
  children: ReactNode;
};

export const CountryContext = createContext({} as TCountryContext);

export function CountryProvider({ children }: TCountryProviderProps) {
  const [country, setCountry] = useState<ECountry>(ECountry.Brasil);

  useEffect(() => {
    const selectedCountry = localStorage.getItem("selectedCountry");
    if (selectedCountry) {
      setCountry(selectedCountry as ECountry);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedCountry", country);
  }, [country]);

  return (
    <CountryContext.Provider value={{ country, setCountry }}>
      {children}
    </CountryContext.Provider>
  );
}

export function useCountry() {
  const context = useContext(CountryContext);
  return context;
}
