import React, {
  ReactElement,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useCountry } from "../../../contexts/CountryContext";
import { useHistory } from "react-router";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  establishment: {
    name: string;
    state: string;
    city: string;
  };

  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CreateLinkDialog(props: Props) {
  const [linkCreated, setLinkCreated] = useState<string | null>(null);
  const { country } = useCountry();
  const history = useHistory();

  useEffect(() => {
    setLinkCreated(null);
  }, [props.open]);

  async function handleCreateLink(): Promise<void> {
    try {
      const response = await api.post("/app/establishment-hashes", {
        establishmentName: props.establishment.name,
        establishmentState: props.establishment.state,
        establishmentCity: props.establishment.city,
        establishmentCountry: country,
      });

      setLinkCreated(response.data.hashUrl);
      toast.success("Link criado com sucesso");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar link");
    }
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.setOpen.bind(null, false)}
      aria-describedby="alert-dialog-slide-description"
    >
      {!linkCreated ? (
        <>
          <DialogTitle>Criar link</DialogTitle>
          <DialogContent sx={{ wordBreak: "break-word" }}>
            <DialogContentText id="alert-dialog-slide-description">
              <p>Nome: {props.establishment?.name}</p>
              <p>Estado{props.establishment?.state}</p>
              <p>Cidade: {props.establishment?.city}</p>
              <p>Pais: {country}</p>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCreateLink}>Criar link</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle sx={{ color: "green" }}>
            Link criado com sucesso
          </DialogTitle>

          <DialogContent sx={{ wordBreak: "break-word" }}>
            <DialogContentText>Link:</DialogContentText>
            <DialogContentText sx={{ color: "green" }}>
              {linkCreated}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => history.push("/settings/links")}>
              Ver links
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
