import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0 0 30px 0;
  display: flex;
  justify-content: center;

  .form-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
  }
  .form-container .box {
    width: 100%;
    max-width: 700px;
    background-color: #fff;
    padding: 10px 0 30px 0;
    border-radius: 12px;
    border: none;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .form-container form {
    width: 100%;
    margin-top: 25px;
    padding: 0 30px;
  }
  .form-container .input-block {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .form-container .input-block + .input-block {
    margin-top: 20px;
  }
  .form-container .input-block-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 30px 0;
  }

  .input-block label {
    margin-bottom: 5px;
  }

  .form-container .input-block input,
  .form-container .input-block select,
  .form-container .input-block textarea {
    width: 100%;
    height: 45px;
    font-family: "Roboto", sans-serif;
    background-color: #f4f4f4;
    border: 2px solid #f4f4f4;
    border-radius: 8px;
    padding: 20px;
    transition: background 0.3s, border 0.3s;
  }
  .form-container .input-block select {
    padding: 0 20px;
  }

  .form-container .input-block input:focus,
  .form-container .input-block select:focus {
    background-color: #fff;
    border: 2px solid #0cd864;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  .upload-container nav ul {
    width: 100%;
    max-height: 415px;
    list-style: none;
    overflow-y: auto;
  }
  .upload-container nav ul li {
    width: 100%;
    height: 60px;
    padding: 40px 0;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
  .upload-container nav ul li:first-child {
    margin-top: 10px;
  }
  .upload-container ul li .div-file {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .upload-container li .div-file .image {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  .upload-container li .div-file .fileName {
    width: 100%;
    max-width: 150px;
    color: #000;
    font-size: 18px;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (min-width: 360px) {
    .upload-container li .div-file .fileName {
      max-width: 200px;
    }
  }
  @media (min-width: 460px) {
    .upload-container li .div-file .fileName {
      max-width: 300px;
    }
  }
  @media (min-width: 560px) {
    .upload-container li .div-file .fileName {
      max-width: 400px;
    }
  }
  @media (min-width: 660px) {
    .upload-container li .div-file .fileName {
      max-width: 500px;
    }
  }
  button.remove-image {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
  .file-add {
    width: 100%;
    height: 100%;
    margin-top: 20px;
  }
  .file-add div {
    width: 100%;
  }
  .file-add .input {
    width: 100%;
    max-width: max-content;
    margin: 0 auto;
  }
  .input-block button[type="submit"] {
    width: 140px;
    height: 45px;
    background-color: #0cd864;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    transition-duration: 0.2s;
    margin: 20px auto;
  }
  .input-block button[type="submit"]:hover {
    background-color: rgba(12, 216, 100, 0.8);
    transition-duration: 0.2s;
  }

  .input-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .input-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }

  .input-checkbox label {
    margin-left: 10px;
  }

  .input-checkbox input {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
