/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import api from "../../services/api";
import { useCountry } from "../../contexts/CountryContext";
import {
  Checkbox,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddLinkIcon from "@mui/icons-material/AddLink";

import { useHistory } from "react-router";
import DeleteDialog from "../../components/DeleteDialog";
import CreateLinkDialog from "./CreateLinkDialog /CreateLinkDialog";

interface Column {
  id:
    | "selected"
    | "name"
    | "channels"
    | "state"
    | "city"
    | "status"
    | "createdAt";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  {
    id: "selected",
    label: "",
    minWidth: 10,
  },
  { id: "name", label: "Nome", minWidth: 150 },
  { id: "channels", label: "Canais", minWidth: 150 },
  {
    id: "state",
    label: "Estado",
    minWidth: 100,
  },
  {
    id: "city",
    label: "Cidade",
    minWidth: 100,
  },
  {
    id: "status",
    label: "Situação",
    minWidth: 100,
  },
  {
    id: "createdAt",
    label: "Data de Criação",
    minWidth: 100,
  },
];

interface Data {
  establishmentId: string;
  selected: boolean;
  name: string;
  channels: string;
  state: string;
  city: string;
  status: string;
  createdAt: string;
}

export default function EstablishmentList() {
  const { country } = useCountry();
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");

  const [establishments, setEstablishments] = useState<Data[]>([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [createLinkDialogOpen, setCreateLinkDialogOpen] = useState(false);

  const selectedEstablishment = useMemo(() => {
    const selected = establishments.find(
      (establishment) => establishment.selected
    );
    return selected!;
  }, [establishments]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const newEstablishments = establishments.map((establishment) => {
      if (establishment.establishmentId === id) {
        return { ...establishment, selected: !establishment.selected };
      }
      return {
        ...establishment,
        selected: false,
      };
    });

    setEstablishments(newEstablishments);
  };

  async function getEstablishments(): Promise<void> {
    try {
      const response = await api.get("/app/establishments", {
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          country,
          search: search.trim().length > 0 ? search : undefined,
        },
      });

      const data = response.data.data.map((establishment: Data) => {
        return {
          ...establishment,
          selected: false,
        };
      });

      setEstablishments(data);
      setCount(response.data.count);
    } catch (error) {
      toast.error("Erro ao buscar os estabelecimentos");
    }
  }

  useEffect(() => {
    getEstablishments();
  }, [page, rowsPerPage, country]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    setSearch("");
  }, [country]);

  useEffect(() => { 
    if (search.trim().length > 0 && search.trim().length < 3) {
      return;
    }

    console.log("search", search);

    const timer = setTimeout(() => {
      getEstablishments();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleNavigateToEdit() {
    history.push(
      `/editar-estabelecimento/${selectedEstablishment?.establishmentId}`
    );
  }

  async function handleUpdateStatus() {
    const establishmentId = selectedEstablishment!.establishmentId;
    const status = selectedEstablishment!.status;
    try {
      await api.put(`app/establishments/updateStatus/${establishmentId}`, {
        status,
      });

      toast.success("Situação atualizada com sucesso");
      const newEstablishments = establishments.map((establishment) => {
        if (establishment.establishmentId === establishmentId) {
          return {
            ...establishment,
            status: status === "Ativa" ? "Inativa" : "Ativa",
          };
        }
        return establishment;
      });

      setEstablishments(newEstablishments);
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error("Houve um problema de conexão, tente novamente.");
      }
    }
  }

  async function handleDeleteEstablishment() {
    try {
      await api.delete(
        `app/establishments/${selectedEstablishment!.establishmentId}`
      );

      const newEstablishments = establishments.filter(
        (establishment) =>
          establishment.establishmentId !==
          selectedEstablishment!.establishmentId
      );
      setEstablishments(newEstablishments);
      setModalOpen(false);
      toast.success("Estabelecimento apagado com sucesso");
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error("Houve um problema de conexão, tente novamente.");
      }
    }
  }
  return (
    <>
      <CreateLinkDialog
        establishment={selectedEstablishment}
        open={createLinkDialogOpen}
        setOpen={setCreateLinkDialogOpen}
      />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selectedEstablishment && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          {selectedEstablishment ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selectedEstablishment.name}
            </Typography>
          ) : (
            <>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Estabelecimentos
              </Typography>

              <TextField
                hiddenLabel
                id="filled-hidden-label-small"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                variant="outlined"
                size="small"
                placeholder="Pesquisar"
                sx={{ mr: 1, width: "300px" }}
              />

              <Tooltip title="Adicionar" sx={{ ml: 1 }}>
                <IconButton
                  onClick={() => history.push("/cadastrar-estabelecimento")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          {selectedEstablishment && (
            <>
              <Tooltip title="Editar">
                <IconButton onClick={handleNavigateToEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Ativar/Desativar">
                <IconButton onClick={handleUpdateStatus}>
                  <PublishIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Apagar">
                <IconButton onClick={() => setModalOpen(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Criar Link">
                <IconButton onClick={() => setCreateLinkDialogOpen(true)}>
                  <AddLinkIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Toolbar>

        <TableContainer sx={{ height: "70vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {establishments.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    selected={row.selected}
                    aria-checked={row.selected}
                    onClick={(event) => handleClick(event, row.establishmentId)}
                    sx={{ cursor: "pointer" }}
                    key={row.establishmentId}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (column.id === "selected") {
                        return (
                          <TableCell padding="checkbox" key={column.id}>
                            <Checkbox
                              color="primary"
                              checked={row.selected}
                              inputProps={{
                                "aria-labelledby": row.establishmentId,
                              }}
                            />
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`;
          }}
        />
      </Paper>

      <DeleteDialog
        open={modalOpen}
        setOpen={setModalOpen}
        handleDelete={handleDeleteEstablishment}
      />
    </>
  );
}
