export const KEY = '@MeuLance:Token';

export const isAuthenticated = () => localStorage.getItem(KEY) !== null;

export const getAuthenticatedUser = () => {
  const token: string = localStorage.getItem(KEY)!;

  return token ? true : false;
};

export const getToken = () => localStorage.getItem(KEY);

export function setToken(token: string) {
  localStorage.setItem(KEY, token);
}

export function removeToken() {
  localStorage.removeItem(KEY);
  window.location.replace('/');
}
