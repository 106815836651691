/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  width: 100%;
  min-width: 100%;
  height: 40px;
  text-align: center;
  font-size: 18px;
  font-family: 'Roboto';
  padding: 0 15px;
  background-color: #fff;
  color: #263238;
  border: 1px solid rgba(60, 62, 68, 0.5);
  box-shadow: 0 0 10px 0 rgba(60, 62, 68, 0.2);
  transition: border 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Container;
