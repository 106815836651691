import { FormEvent, useEffect, useState } from "react";
import { Trash, Download, X } from "react-feather";

import { toast } from "react-toastify";
import api from "../../services/api";
import Loader from "../../components/Loader";
import { Container } from "./styles";
import { toBase64 } from "../../utils/toBase64";
import Upload from "../../components/Upload";
import { useHistory, useParams } from "react-router";
import DeleteDialog from "../../components/DeleteDialog";
import Select from "../../components/Select";
import { statesOfBrazil } from "../../data/statesOfBrazil";
import { statesOfUruguay } from "../../data/statesOfUruguay";
import { countries } from "../../data/countries";
import { statesOfPortugal } from "../../data/statesOfPortugal";
import { ECountry } from "../../contexts/CountryContext";
import DraggableResizableLogos from "../../components/CamLayoutEditor";
import UseLogosProps, { LogosProps, Image as PropsImage } from "../../components/CamLayoutEditor/UseLogosProps";
import PermittedVisitorsDialog from "../../components/PermittedVisitorsDialog";
import { Button } from "@mui/material";

// interface Image extends File {
//   data: string;
// }

interface IParams {
  id: string;
}

interface IImageToDelete {
  imgId: string | undefined;
  channelId: string | undefined;
  index: number | undefined;
}

interface IOption {
  label: string;
  value: string;
}

interface TImage extends File {
  id?: string;
  url: string;
  path: string;
  data?: string;
  initialPosition?: { y: number; x: number; width?: number; height?: number };
};

// Type for channel logos
type TChannelLogos = {
  [channelId: string]: {
    channelName: string;
    logos: PropsImage[];
  };
}

interface Channel {
  channelId: string;
  name: string;
}

interface Channels {
  [key: string]: Channel; // Dynamic key-value pairs
}
export function EditBlock() {
  const [establishment, setEstablishment] = useState<string>("");
  const [channels, setChannels] = useState<Channels>();
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [channelsIds, setchannelsIds] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState(true);
  const [advertisersLogos, setAdvertisersLogos] = useState<TChannelLogos>({});
  const [baseImage, setBaseImage] = useState<PropsImage>();
  const [selectedChannel, setSelectedChannel] = useState<string | undefined>();
  const [selectedState, setSelectedState] = useState<IOption>();
  const [selectedCountry, setSelectedCountry] = useState<IOption>();
  const [newLogUploaded, setNewLogUploaded] = useState<Boolean>(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isChangedLogos, setIsChangedLogos] = useState(false);

  const [isPrivate, setIsPrivate] = useState(false);
  const [permittedVisitors, setPermittedVisitors] = useState([]);

  const [permittedVisitorsModal, setPermittedVisitorsModal] = useState(false);

  const [loader, setLoader] = useState("activated");
  const [loaderCamLayoutEditor, setLoaderCamLayoutEditor] = useState("activated");
  const [modalOpen, setModalOpen] = useState(false);
  const [channelsToDelete, setChannelsToDelete] = useState<Channels | undefined>();
  const [imageToDelete, setImageToDelete] = useState<IImageToDelete>({
    imgId: undefined,
    channelId: undefined,
    index: undefined,
  });

  const history = useHistory();
  const { id: establishmentId } = useParams<IParams>();
  const [camLayoutEditor, setCamLayoutEditor] = useState<any>()

  useEffect(() => {
    if (!establishment || !channels) return;
    try {
      if (!selectedChannel) return

      setLoaderCamLayoutEditor("activated")
      api
        .get('app/videos/cam-print', {
          headers: {
            establishmentFolderName: establishment,
            channelFolderName: channels[selectedChannel].name
          }
        })
        .then(async (response) => {

          const upImage: PropsImage = { data: 'data:image/jpeg;base64,' + response.data.thumbnail, arrayBuffer: response.data.thumbnail } as PropsImage;

          const upBaseImage = { ...upImage, width: 1920, height: 1080 }
          upBaseImage && setBaseImage(upBaseImage);

        }).catch(err => {
          setLoaderCamLayoutEditor("disabled")
          console.log(err)
        }).finally(() => {
          setLoaderCamLayoutEditor("disabled")
        });
    } catch (error) {
      console.log(error)
    }
  }, [establishment, channels, selectedChannel]);

  useEffect(() => {
    if (!establishmentId) return;

    api
      .get(`app/establishments/${establishmentId}`)
      .then(async (response) => {
        setName(response.data.establishment.name);
        setSelectedState({
          label: response.data.establishment.state,
          value: response.data.establishment.state,
        });
        setCity(response.data.establishment.city);
        setAdvertisersLogos(response.data.establishment?.channelsLogos);
        setEstablishment(response.data.establishment.folderName);
        setChannels(response.data.establishment.channels);
        setchannelsIds(Object.keys(response.data.establishment.channels))
        console.log('First Channel', Object.keys(response.data.establishment.channels)[0])
        if (Object.keys(response.data.establishment.channels)[0]){
          setSelectedChannel(response.data.establishment.channels[Object.keys(response.data.establishment.channels)[0]].channelId)
        }
        setSelectedCountry({
          label: response.data.establishment.country,
          value: response.data.establishment.country,
        });

        setIsPrivate(response.data.establishment.isPrivate);
        setPermittedVisitors(
          response.data.permittedVisitors.map((permission: any) => {
            return {
              label: permission.visitorEmail,
              value: permission.visitorId,
            };
          })
        );

        setIsPrivate(response.data.establishment.isPrivate);
        setPermittedVisitors(
          response.data.permittedVisitors.map((permission: any) => {
            return {
              label: permission.visitorEmail,
              value: permission.visitorId,
            };
          })
        );

        let state: IOption | undefined;
        if (response.data.establishment.country === ECountry.Brasil) {
          state = statesOfBrazil.find(
            (state) => state.value === response.data.establishment.state
          );
        }
        if (response.data.establishment.country === ECountry.Uruguai) {
          state = statesOfUruguay.find(
            (state) => state.value === response.data.establishment.state
          );
        }
        if (response.data.establishment.country === ECountry.Portugal) {
          state = statesOfPortugal.find(
            (state) => state.value === response.data.establishment.state
          );
        }
        setSelectedState(state);

        localStorage.setItem("selectedChannels", response.data.channels);
      })
      .finally(() => {
        setLoader("disabled");
      });
  }, [establishmentId]);

  useEffect(() => {
    if (
      imageToDelete.channelId === undefined ||
      imageToDelete.index === undefined
    )
      return;
    setModalOpen(true);

  }, [imageToDelete]);

  function deleteImage({ imgId, channelId, index }: IImageToDelete) {
    setImageToDelete({
      imgId,
      channelId,
      index,
    });
  }

  async function handleUploadAdvertisersLogos(uploadedImages: Array<PropsImage>, input: Event, channelId: string) {
    if (!advertisersLogos || !channelId) return;
    const upImages: TChannelLogos = advertisersLogos;
    for (const image of uploadedImages) {
      const { width, height } = await getImageDimensions(await toBase64(image))
      upImages[channelId].logos = [...upImages[channelId].logos, { ...image, data: await toBase64(image), width: image?.width || width, height: image?.height || height }]
    }
    setAdvertisersLogos(upImages);
    setIsChangedLogos(true);
    setNewLogUploaded(true);
  }

  async function updateLogos(channelsIds: string[], advertisersLogos: { [x: string]: { logos: any[]; }; }, logosToUpdate: any) {
    try {
      const mappedResponses = await Promise.all(
        channelsIds.map(async (channelId) => {
          if (!advertisersLogos[channelId] || !advertisersLogos[channelId].logos.length) {
            console.warn('No logos found for channelId:', channelId);
            return [];
          }

          return await Promise.all(
            advertisersLogos[channelId].logos.map(async (logo) => {
              try {
                const response = await api.post('app/channel-logos', {
                  ...logosToUpdate, logos: {
                    [`${channelId}`]: [logo]
                  }
                });
                return response.data;
              } catch (err) {
                toast.error('Erro ao fazer upload de image.');
                console.error('Error updating Channel-logos for logo:', logo, err);
                return null;
              } finally {
                // toast.success('Sucesso ao fazer upload das imagens.');
              }
            })
          );
        })
      );
      toast.success('Sucesso ao fazer upload das imagens.');
      return mappedResponses;
    } catch (error) {
      console.error('Error in updating logos:', error);
    }
  }


  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (establishment.trim() === "") {
      toast.warn("Nome do estabelecimento é obrigatório");
      return;
    }
    if (name.trim() === "") {
      toast.warn("Nome do estabelecimento é obrigatório");
      return;
    }
    if (!selectedCountry) {
      toast.warn("Selecione um país");
      return;
    }
    if (!selectedState) {
      toast.warn("Selecione um estado");
      return;
    }
    if (city.trim() === "") {
      toast.warn("Nome da cidade é obrigatório");
      return;
    }
    if (!channels) {
      toast.warn("Canais é obrigatório");
      return;
    }

    setLoader("activated");

    const data = new FormData();
    console.log(channelsToDelete)
    data.append("establishment", establishment);
    data.append("channels", JSON.stringify(channels));
    data.append("name", name);
    data.append("country", String(selectedCountry?.value));
    data.append("state", String(selectedState?.value));
    data.append("city", city);
    data.append("isChanged", String(isChanged));
    channelsToDelete && data.append("channelsToDelete", JSON.stringify(channelsToDelete));

    data.append("isPrivate", String(isPrivate));
    data.append(
      "permittedVisitors",
      permittedVisitors.map((visitor: any) => visitor.value).join(",")
    );

    try {
      if (isChanged) {
        await api.put(`app/establishments/${establishmentId}`, data).then(async data => {
          setIsChanged(false);
        }).catch(err => {
          if (err?.response?.status === 400 || err?.response?.status === 401) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Houve um problema de conexão, tente novamente.");
          }
        })
      }

      if (isChangedLogos && advertisersLogos) {
        const logosToUpdate = {
          establishmentId,
          channelId: selectedChannel,
          logos: advertisersLogos
        };
        const mappedResponses = await updateLogos(channelsIds, logosToUpdate.logos, logosToUpdate)
        console.log(mappedResponses)
      }



      history.push("/estabelecimentos");
    } catch (error) {
      console.log(error);
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error("Houve um problema de conexão, tente novamente.");
      }
    }
    setLoader("disabled");
  }

  const handleDeleteCurrentAdvertisersLogo = async () => {
    try {
      setLoader("activated");
      if (!imageToDelete.imgId) {
        setAdvertisersLogos({
          ...advertisersLogos, [selectedChannel!]: {
            ...advertisersLogos[selectedChannel!], logos: advertisersLogos[selectedChannel!].logos.filter(
              (image: File, i: number) => i !== imageToDelete.index
            )
          }
        });
      } else {
        await api.delete(
          `app/channel-logos/${imageToDelete.imgId}`
        ).then(() => {
          setAdvertisersLogos({
            ...advertisersLogos, [selectedChannel!]: {
              ...advertisersLogos[selectedChannel!], logos: advertisersLogos[selectedChannel!].logos.filter(
                (image: File, i: number) => i !== imageToDelete.index
              )
            }
          });
        })
      }

      setImageToDelete({
        imgId: undefined,
        channelId: undefined,
        index: undefined,
      });

      setIsChangedLogos(true);
      setModalOpen(false);
      toast.success("Imagem apagada com sucesso");
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error("Houve um problema de conexão, tente novamente.");
      }
    }
    setLoader("disabled");
  };

  function getImageDimensions(base64: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = base64;
    });
  }

  async function handleUploadBaseImage(uploadedImages: Array<PropsImage>) {
    let upImage: PropsImage = {} as PropsImage;

    upImage = uploadedImages[0];
    upImage.data = await toBase64(uploadedImages[0]);
    const { width, height } = await getImageDimensions(upImage.data)
    upImage = { ...upImage, width, height }

    upImage['baseImage'] = true
    if (!upImage || !upImage.width || !upImage.height) {
      console.error('Imagem base inválida.');
      return;
    }

    setBaseImage(upImage);
  }

  const handleLogoPositions = (
    channelId: string,
    imagePath: string,
    position: { y: number; x: number; width: number; height: number }
  ) => {
    setAdvertisersLogos((prevState) => {
      const currentChannel = prevState[channelId];

      if (!currentChannel) {
        console.error(`Channel with ID ${channelId} does not exist.`);
        return prevState;
      }

      const logoIndex = currentChannel.logos.findIndex(logo => logo.path === imagePath);

      if (logoIndex === -1) {
        console.error(`Logo with path ${imagePath} does not exist for channel ${channelId}.`);
        return prevState;
      }

      const updatedLogos = currentChannel.logos.map((logo, i) => {
        if (logo.path === imagePath) {
          return {
            ...logo,
            x: position.x,
            y: position.y,
            width: position.width,
            height: position.height,
          };
        }
        return logo;
      });


      return {
        ...prevState,
        [channelId]: {
          ...currentChannel,
          logos: updatedLogos,
        },
      };
    });

    setIsChangedLogos(true);
  };

  function handleDeleteBaseImage() {
    setBaseImage(undefined);
  }

  useEffect(() => {
    setCamLayoutEditor(baseImage && advertisersLogos && selectedChannel && (
      <DraggableResizableLogos
        isAdmin={isAdmin}
        baseImage={baseImage}
        channelId={selectedChannel!}
        onLogoPositionChange={handleLogoPositions}
        logos={UseLogosProps({ advertisersLogos: advertisersLogos[selectedChannel!].logos, isAdmin }) as unknown as LogosProps}
      />
    ))
    setLoaderCamLayoutEditor("disabled")
    if (newLogUploaded) {
      setNewLogUploaded(false)
    }
  }, [baseImage, selectedChannel, isAdmin, isChangedLogos, newLogUploaded])

  return (
    <>
      <Loader className={loader} />
      <Container>
        <div className="form-container">
          <div className="box">
            <form onSubmit={(event) => handleSubmit(event)}>
              <div className="input-block">
                <label htmlFor="establishment">Estabelecimento</label>
                <input
                  type="text"
                  id="establishment"
                  value={establishment}
                  onChange={(event) => setEstablishment(event.target.value)}
                />
              </div>

              <div className="input-block">
                <label htmlFor="name">Nome</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>

              <div className="input-block">
                <label htmlFor="name">Pais</label>
                <Select
                  placeholder="Selecione"
                  isMulti={false}
                  options={countries}
                  value={selectedCountry}
                  setValue={setSelectedCountry}
                />
              </div>

              {selectedCountry?.value === ECountry.Brasil && (
                <div className="input-block">
                  <label htmlFor="name">UF</label>
                  <Select
                    placeholder="Selecione"
                    isMulti={false}
                    options={statesOfBrazil}
                    value={selectedState}
                    setValue={setSelectedState}
                  />
                </div>
              )}

              {selectedCountry?.value === ECountry.Uruguai && (
                <div className="input-block">
                  <label htmlFor="name">UF</label>
                  <Select
                    placeholder="Selecione"
                    isMulti={false}
                    options={statesOfUruguay}
                    value={selectedState}
                    setValue={setSelectedState}
                  />
                </div>
              )}

              {selectedCountry?.value === ECountry.Portugal && (
                <div className="input-block">
                  <label htmlFor="name">UF</label>
                  <Select
                    placeholder="Selecione"
                    isMulti={false}
                    options={statesOfPortugal}
                    value={selectedState}
                    setValue={setSelectedState}
                  />
                </div>
              )}

              <div className="input-block">
                <label htmlFor="city">Cidade</label>
                <input
                  type="text"
                  id="city"
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                />
              </div>

              <div className="input-block">
                <label htmlFor="channels">Canais</label>
                <nav>
                  <ul>
                    {channels && (
                      typeof channels === 'object' && !Array.isArray(channels) && (
                        Object.entries(channels).map(([key, channel]) => {
                          //console.log('RENDER CHANNEL', channel);
                          return (
                            <>
                              <li key={key}>
                                <div className="div-file">
                                  <input
                                    type="channel-input"
                                    key={channel.channelId as string} // Add a key for better list rendering
                                    id={channel.channelId}
                                    value={channel.name} // Use the name directly
                                    placeholder="Ex: canal 1,canal 2,canal 3"
                                    onChange={(event) => {
                                      const updatedChannels = {
                                        ...channels,
                                        [channel.channelId]: {
                                          ...channel,
                                          name: event.target.value, // Update name based on user input
                                        },
                                      };
                                      setChannels(updatedChannels);
                                    }}
                                  />
                                  {/* <Trash color="#f20a4d" onClick={() =>
                                    setChannelsToDelete({ ...channelsToDelete, channel })
                                  } /> */}
                                </div>
                              </li>

                              <div className="input-block">
                                <div className="upload-container">
                                  <label htmlFor="channels">Logos dos Patrocinadores</label>
                                  <nav>
                                    <ul>
                                      {channel && advertisersLogos && advertisersLogos[channel.channelId as string]?.logos?.map((image: TImage, index: number) => {
                                        return image.path && (
                                          <li key={index}>
                                            <div className="div-file">
                                              {
                                                image.data ?
                                                  <img
                                                    className="image"
                                                    src={image.data}
                                                    alt={image.path}
                                                  />
                                                  :
                                                  <img
                                                    className="image"
                                                    src={image.url}
                                                    alt={image.path.split("/")[2]}
                                                  />
                                              }
                                              <span className="fileName">{image.path.split("/")[2] || image.path}</span>
                                            </div>
                                            <a
                                              className="remove-image"
                                              href={image.url}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <Download color="#0078da" />
                                            </a>
                                            <button
                                              className="remove-image"
                                              onClick={() =>
                                                deleteImage({
                                                  imgId: image.id,
                                                  channelId: channel.channelId,
                                                  index: index,
                                                })
                                              }
                                              type="button"
                                            >
                                              <Trash color="#f20a4d" />
                                            </button>
                                          </li>
                                        );

                                      })}
                                    </ul>
                                    {advertisersLogos && (
                                      <div className="file-add">
                                        <div>
                                          <Upload
                                            title="Adicionar"
                                            channelId={channel.channelId}
                                            onUpload={(file: Array<PropsImage>, event: Event) => handleUploadAdvertisersLogos(file, event, channel.channelId)}
                                            maxFiles={10}
                                          />
                                        </div>
                                      </div>
                                    )}

                                  </nav>
                                </div>
                              </div>

                              <div className="input-block">
                                <div className="upload-container">
                                  <nav>
                                    <ul>
                                      {!baseImage && (
                                        <div>

                                          <span>Imagem da Câmera não encontrada! Suba uma imagem base.</span>
                                          <div className="file-add">
                                            <div>
                                              <Upload
                                                title="Adicionar"
                                                onUpload={handleUploadBaseImage}
                                                maxFiles={1}
                                              />

                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {baseImage?.data &&
                                        <div className="remove-base-image">                                        
                                          <button
                                            className="remove-image"
                                            onClick={handleDeleteBaseImage}
                                            type="button"
                                          >
                                            <X color="#f20a4d" />
                                          </button>
                                        </div>
                                      }
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                              {loaderCamLayoutEditor === "activated" ? <Loader className={loaderCamLayoutEditor} /> : camLayoutEditor}
                            </>
                          )
                        })
                      ))
                    }
                  </ul>
                </nav>
              </div>

              <div className="input-block-row">
                <div className="input-checkbox">
                  <input
                    type="checkbox"
                    id="private"
                    checked={isPrivate}
                    onChange={() => setIsPrivate(!isPrivate)}
                  />
                  <label htmlFor="isPrivate">Estabelecimento privado</label>
                </div>

                {isPrivate && (
                  <div className="input-button">
                    <Button
                      onClick={() => setPermittedVisitorsModal(true)}
                      size="small"
                      color="primary"
                      variant="contained"
                    >
                      Visitantes
                    </Button>
                  </div>
                )}
              </div>

              <div className="input-block">
                <button type="submit">Salvar</button>
              </div>
            </form>
          </div>
        </div>
      </Container>
      <DeleteDialog
        open={modalOpen}
        setOpen={setModalOpen}
        handleDelete={() => handleDeleteCurrentAdvertisersLogo()}
      />
      <PermittedVisitorsDialog
        open={permittedVisitorsModal}
        setOpen={setPermittedVisitorsModal}
        permittedVisitors={permittedVisitors}
        setPermittedVisitors={setPermittedVisitors}
      />
    </>
  );
}