import axios from "axios";
import { getToken } from "./auth";

const lambda = axios.create({
  baseURL: process.env.REACT_APP_LAMBDA_CONTACTS_URL,
});

lambda.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default lambda;
