import { Routes } from "./routes";
import Toast from "./components/Toast";
import { GlobalStyles } from "./styles/GlobalStyles";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  return (
    <>
      <GlobalStyles />
      <Toast />
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </>
  );
}

export default App;
