import React from 'react';

import Container from './styles';

type TLoader = {
  className: string;
};

const Loader = ({ className }: TLoader) => (
  <Container>
    <div className={className}>
      <div className="loader" />
    </div>
  </Container>
);

export default Loader;
