/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import api from "../../services/api";
import {
  Checkbox,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import DeleteDialog from "../../components/DeleteDialog";

interface Column {
  id: "selected" | "establishmentName" | "fullAddress" | "createdAt";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  {
    id: "selected",
    label: "",
    minWidth: 10,
  },
  { id: "establishmentName", label: "Estabelecimento", minWidth: 150 },
  { id: "fullAddress", label: "Endereço", minWidth: 150 },
  {
    id: "createdAt",
    label: "Data de Criação",
    minWidth: 100,
  },
];

interface Data {
  hashUrl: string;
  fullAddress: string;
  selected: boolean;
  establishmentName: string;
  createdAt: string;
}

export default function EstablishmentHashList() {
  const [hashes, setHashes] = useState<Data[]>([]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const selectedHash = useMemo(() => {
    const selected = hashes.find((hash) => hash.selected);
    return selected!;
  }, [hashes]);

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const newEstablishmentHashes = hashes.map((hash) => {
      if (hash.hashUrl === id) {
        return { ...hash, selected: !hash.selected };
      }
      return {
        ...hash,
        selected: false,
      };
    });

    setHashes(newEstablishmentHashes);
  };

  const getEstablishmentHashes = useCallback(async () => {
    try {
      const response = await api.get("/app/establishment-hashes");

      const data = response.data.map((hash: Data) => {
        return {
          ...hash,
          selected: false,
        };
      });

      setHashes(data);
    } catch (error) {
      toast.error("Erro ao buscar os estabelecimentos");
    }
  }, []);

  useEffect(() => {
    getEstablishmentHashes();
  }, []);

  async function handleDeleteEstablishmentHash() {
    try {
      await api.delete(`app/establishment-hashes/${selectedHash!.hashUrl}`);

      const newEstablishmentHashes = hashes.filter(
        (hash) => hash.hashUrl !== selectedHash!.hashUrl
      );
      setHashes(newEstablishmentHashes);
      setDeleteDialogOpen(false);
      toast.success("Link apagado com sucesso");
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error("Houve um problema de conexão, tente novamente.");
      }
    }
  }
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {hashes.length === 0 ? (
          <Typography
            sx={{ p: 2 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Nenhum link cadastrado
          </Typography>
        ) : (
          <>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selectedHash && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity
                    ),
                }),
              }}
            >
              {selectedHash ? (
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selectedHash.establishmentName}
                </Typography>
              ) : (
                <>
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Links
                  </Typography>
                  {/* <Tooltip title="Adicionar" sx={{ ml: 1 }}>
                <IconButton
                  onClick={() => history.push("/cadastrar-estabelecimento")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip> */}
                </>
              )}
              {selectedHash && (
                <Tooltip title="Apagar">
                  <IconButton onClick={() => setDeleteDialogOpen(true)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Toolbar>

            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hashes.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        selected={row.selected}
                        aria-checked={row.selected}
                        onClick={(event) => handleClick(event, row.hashUrl)}
                        sx={{ cursor: "pointer" }}
                        key={row.hashUrl}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          if (column.id === "selected") {
                            return (
                              <TableCell padding="checkbox" key={column.id}>
                                <Checkbox
                                  color="primary"
                                  checked={row.selected}
                                  inputProps={{
                                    "aria-labelledby": row.hashUrl,
                                  }}
                                />
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{ wordBreak: "break-word" }}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
      <DeleteDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        handleDelete={handleDeleteEstablishmentHash}
      />
    </>
  );
}
