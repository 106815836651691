import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import ExportVisitorsDialog from "../../components/ExportVisitorsDialog";
import DeleteDialog from "../../components/DeleteDialog";
import api from "../../services/api";
import lambda from "../../services/lambda";

interface Column {
  id:
    | "selected"
    | "name"
    | "phone"
    | "email"
    | "establishmentName"
    | "createdAt";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  {
    id: "name",
    label: "Nome",
    minWidth: 150,
  },
  { id: "phone", label: "Telefone", minWidth: 150 },
  { id: "email", label: "E-mail", minWidth: 150 },
  { id: "establishmentName", label: "Estabelecimento", minWidth: 150 },
  {
    id: "createdAt",
    label: "Data de Cadastro",
    minWidth: 100,
  },
];

interface Data {
  selected: boolean;
  visitorId: string;
  name: string;
  phone: string;
  email: string;
  createdAt: string;
  establishmentName: string;
}

export function VisitorsList() {
  const [visitors, setVisitors] = useState<Data[]>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [exportVisitorsModalOpen, setExportVisitorsModalOpen] = useState(false);
  const [receiptEmail, setReceiptEmail] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");

  const selectedVisitor = useMemo(() => {
    const selected = visitors.find((visitor) => visitor.selected);
    return selected!;
  }, [visitors]);

  async function getVisitors() {
    api
      .get("app/visitors", {
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          search: search.trim().length > 0 ? search : undefined,
        },
      })
      .then((response) => {
        setVisitors(response.data.data);
        setCount(response.data.count);
      })
      .catch((error) => {
        if (
          error?.response?.status === 400 ||
          error?.response?.status === 401
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Houve um problema de conexão, tente novamente.");
        }
      });
  }

  useEffect(() => {
    getVisitors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (search.trim().length > 0 && search.trim().length < 3) {
      return;
    }

    const timer = setTimeout(() => {
      getVisitors();
    }, 500);

    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const newVisitors = visitors.map((visitor) => {
      if (visitor.visitorId === id) {
        return { ...visitor, selected: !visitor.selected };
      }
      return {
        ...visitor,
        selected: false,
      };
    });

    setVisitors(newVisitors);
  };

  async function handleDeleteEstablishment() {
    try {
      await api.delete(`app/visitors/${selectedVisitor.visitorId}`);

      const newVisitors = visitors.filter(
        (visitor) => visitor.visitorId !== selectedVisitor.visitorId
      );
      setVisitors(newVisitors);
      setDeleteModalOpen(false);
      toast.success("Usuário apagado com sucesso");
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error("Houve um problema de conexão, tente novamente.");
      }
    }
  }

  async function handleExportVisitors() {
    if (receiptEmail === "") {
      toast.info("Digite um email");
      return;
    }

    try {
      lambda.post(`/contacts/export`, { receiptEmail });

      setReceiptEmail("");
      setExportVisitorsModalOpen(false);

      toast.success("Solicitação realizada.");
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        // @ts-ignore
        toast.error(error.response.data.message);
      } else {
        toast.error("Houve um problema de conexão, tente novamente.");
      }
    }
  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selectedVisitor && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          {selectedVisitor ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selectedVisitor.name}
            </Typography>
          ) : (
            <>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Visitantes
              </Typography>

              <Tooltip title="Exportar">
                <Button
                  onClick={() => setExportVisitorsModalOpen(true)}
                  size="small"
                  color="primary"
                  variant="contained"
                  sx={{ ml: 1, mr: 1, width: "150px" }}
                >
                  Exportar
                </Button>
              </Tooltip>

              <TextField
                hiddenLabel
                id="filled-hidden-label-small"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                variant="outlined"
                size="small"
                placeholder="Pesquisar"
                sx={{ ml: 1, mr: 1, width: "300px" }}
              />
            </>
          )}
          {selectedVisitor && (
            <>
              <Tooltip title="Apagar">
                <IconButton onClick={() => setDeleteModalOpen(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Toolbar>

        <TableContainer sx={{ height: "70vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {visitors.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    selected={row.selected}
                    aria-checked={row.selected}
                    onClick={(event) => handleClick(event, row.visitorId)}
                    sx={{ cursor: "pointer" }}
                    key={row.visitorId}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (column.id === "selected") {
                        return (
                          <TableCell padding="checkbox" key={column.id}>
                            <Checkbox
                              color="primary"
                              checked={row.selected}
                              inputProps={{
                                "aria-labelledby": row.visitorId,
                              }}
                            />
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`;
          }}
        />
      </Paper>

      <DeleteDialog
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        handleDelete={handleDeleteEstablishment}
      />

      <ExportVisitorsDialog
        open={exportVisitorsModalOpen}
        setOpen={setExportVisitorsModalOpen}
        handle={handleExportVisitors}
        receiptEmail={receiptEmail}
        setReceiptEmail={setReceiptEmail}
      />
    </>
  );
}
