import React, { ReactNode, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { AppBar } from "../AppBar";
import { Drawer } from "../Drawer";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

type LayoutProps = {
  title: string;
  children: ReactNode;
};

export function Layout({ title, children }: LayoutProps) {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar open={open} setOpen={setOpen} title={title} />
      <Drawer open={open} setOpen={setOpen} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: "100vh" }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
