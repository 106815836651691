import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
  }

  .form-container .box {
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    padding: 10px 0 30px 0;
    border-radius: 12px;
    border: none;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .form-container .box span {
    color: #0cd864;
    font-weight: 700;
    font-size: 30px;
    margin-top: 10px;
  }

  .form-container form {
    width: 100%;
    margin-top: 25px;
    padding: 0 30px;
  }

  .form-container .input-block {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .form-container .input-block + .input-block {
    margin-top: 20px;
  }

  .input-block label {
    margin-bottom: 5px;
  }

  .form-container input {
    width: 100%;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4;
    border: 2px solid #f4f4f4;
    border-radius: 8px;
    padding: 20px;
    transition: background 0.3s, border 0.3s;
  }

  .form-container input:focus {
    background-color: #fff;
    border: 2px solid #0cd864;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .input-block button[type='submit'] {
    width: 140px;
    height: 45px;
    background-color: #0cd864;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    transition-duration: 0.2s;
    margin: 0 auto;
  }

  .input-block button[type='submit']:hover {
    background-color: rgba(12, 216, 100, 0.8);
    transition-duration: 0.2s;
  }
`;
